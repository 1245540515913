@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  outline: 2px solid white;
} */

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.ZIndexFull {
  z-index: 99999;
}

.scrollWidth {
  width: 26rem;
}

.CardBack {
  background :rgba(32, 40, 78, 0.574);
  border-radius: 1rem;
}

.CardContent {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.CardBackTitle {
  background :rgba(32, 40, 78, 0.645);
  border-radius: 1rem;
  padding-left: 1rem;
  padding-bottom: .5rem;
  padding-top: .5rem;
}

.HourlyHighLow {
  background :rgba(32, 40, 78, 0.832);
  padding-left: .5rem;
  padding-right: .5rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem ;

}

.AddCardBack {
  background :rgba(32, 40, 78, 0.929);
  color: white
}

.Grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: .5rem;
  grid-auto-rows: minmax(100px, 50%);
}

.GridItem {
  background :rgba(32, 40, 78, 0.645);
  border-radius: 1rem;
  color: white
}

.Fade:before {
    content:'';
    width:100%;
    height:100%;    
    position:absolute;
    left:0;
    top:0;
    background:linear-gradient(transparent 150px, white);
}

.WeatherIconShiftY {
  transform: translateY(-.5rem)
}
.WeatherIconShiftX {
  transform: translateX(-1rem)
}


@import url("//fonts.googleapis.com/css?family=Roboto:400,300,100");
* {
  box-sizing: border-box; }

  @import url("//fonts.googleapis.com/css?family=Roboto:400,300,100");
  * {
    box-sizing: border-box; }
  
  body, html {
    background-color: #27415b;
    height: 100%;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 300;
    text-align: center; }
  
  .showing {
    font-size: 50%;
    width: auto;
    height: auto;
    position: relative;
    top: -5px;
    transform: translateY(20px);
    margin: 0 auto; }
    .showing .title {
      height: 50px;
      line-height: 50px;
      text-transform: uppercase;
      font-size: 30px; }
    .showing .description {
      height: 50px;
      line-height: 50px;
      font-weight: 100; }
      .showing .description li {
        display: block;
        width: 100px;
        float: left; }
  
  .scene {
    width: auto;
    height: auto;
     }
    .scene .states .state {
      width: 100px;
      height: 100px;
      float: left;
      position: relative;
      overflow: hidden; }
      .scene .states .state.sunny {
        transform: translateX(10px);
        background-color: transparent; }
      .scene .states .state.sunny_cloudy {
        background-color: transparent; }
        .scene .states .state.sunny_cloudy .sun {
          width: 25px;
          height: 25px; }
      .scene .states .state.cloudy {
        background-color: transparent; }
        .scene .states .state.cloudy .cloud [class^="circle-"], .scene .states .state.cloudy .cloud [class^="circle-"]:after, .scene .states .state.cloudy .cloud .rect, .scene .states .state.cloudy .cloud .rect:after {
          background-color: #BDBDBD; }
        .scene .states .state.cloudy .cloud .circle-3 {
          background-color: transparent; }
      .scene .states .state.hazy {
        background-color: transparent; }
        .scene .states .state.hazy .cloud .circle-3 {
          background-color: transparent; }
        .scene .states .state.hazy .haze .lines [class^="line-"] {
          background-color: #FFF;
          width: 40px;
          height: 5px;
          border-radius: 5px;
          position: absolute;
          left: 30px;
          background-color: #9E9E9E;
          animation: haze 1s infinite;
          animation-direction: alternate;
          animation-timing-function: linear; }
        .scene .states .state.hazy .haze .lines .line-1 {
          top: 70px; }
        .scene .states .state.hazy .haze .lines .line-2 {
          top: 80px;
          animation-delay: .1s; }
      .scene .states .state.rainy {
        background-color: transparent; }
        .scene .states .state.rainy .cloud [class^="circle-"], .scene .states .state.rainy .cloud [class^="circle-"]:after, .scene .states .state.rainy .cloud .rect, .scene .states .state.rainy .cloud .rect:after {
          background-color: #9E9E9E; }
        .scene .states .state.rainy .cloud .circle-3 {
          background-color: transparent; }
        .scene .states .state.rainy .rain .drops [class^="drop-"] {
          background-color: #03A9F4;
          width: 4px;
          height: 15px;
          border-radius: 4px;
          position: absolute;
          transform: rotate(20deg) translateY(-20px);
          animation: rain 1s infinite;
          -moz-animation-timing-function: linear; }
        .scene .states .state.rainy .rain .drops .drop-1, .scene .states .state.rainy .rain .drops .drop-2, .scene .states .state.rainy .rain .drops .drop-3, .scene .states .state.rainy .rain .drops .drop-4 {
          top: 67px; }
        .scene .states .state.rainy .rain .drops .drop-1 {
          left: 30px; }
        .scene .states .state.rainy .rain .drops .drop-2 {
          left: 40px;
          animation-delay: .25s; }
        .scene .states .state.rainy .rain .drops .drop-3 {
          left: 50px;
          animation-delay: .5s; }
        .scene .states .state.rainy .rain .drops .drop-4 {
          left: 60px;
          animation-delay: .75s; }
      .scene .states .state.snowy {
        background-color: transparent; }
        .scene .states .state.snowy .cloud [class^="circle-"], .scene .states .state.snowy .cloud [class^="circle-"]:after, .scene .states .state.snowy .cloud .rect, .scene .states .state.snowy .cloud .rect:after {
          background-color: #BDBDBD; }
        .scene .states .state.snowy .cloud .circle-3 {
          background-color: transparent; }
        .scene .states .state.snowy .snow .flakes [class^="flake-"] {
          background-color: #FFF;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          transform: translateY(-10px);
          animation: snow 1s infinite; }
        .scene .states .state.snowy .snow .flakes .flake-1, .scene .states .state.snowy .snow .flakes .flake-2, .scene .states .state.snowy .snow .flakes .flake-3, .scene .states .state.snowy .snow .flakes .flake-4 {
          top: 70px; }
        .scene .states .state.snowy .snow .flakes .flake-1 {
          left: 30px; }
        .scene .states .state.snowy .snow .flakes .flake-2 {
          left: 40px;
          animation-delay: .25s; }
        .scene .states .state.snowy .snow .flakes .flake-3 {
          left: 50px;
          animation-delay: .5s; }
        .scene .states .state.snowy .snow .flakes .flake-4 {
          left: 60px;
          animation-delay: .75s; }
      .scene .states .state.stormy {
        background-color: transparent; }
        .scene .states .state.stormy .cloud [class^="circle-"], .scene .states .state.stormy .cloud [class^="circle-"]:after, .scene .states .state.stormy .cloud .rect, .scene .states .state.stormy .cloud .rect:after {
          background-color: #BDBDBD; }
        .scene .states .state.stormy .cloud .circle-3 {
          background-color: transparent; }
        .scene .states .state.stormy .flash {
            border-radius: 50%;
          background-color: #FF0;
          width: 100px;
          height: 100px;
          animation: flash 5s infinite;
          opacity: 0;
          position: absolute; }
        .scene .states .state.stormy .thunder, .scene .states .state.stormy .thunder:after {
          background-color: #F9DB62;
          width: 5px;
          height: 10px;
          position: absolute; }
        .scene .states .state.stormy .thunder {
          top: 68px;
          left: 45px;
          transform: skewX(-40deg) translateY(-20px);
          animation: thunder 5s infinite; }
          .scene .states .state.stormy .thunder:after {
            content: '';
            top: 5px;
            left: 5px; }
  
  .sun {
    background-color: #F9DB62;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 20px; }
  
  .cloud .circles [class^="circle-"], .cloud .circles [class^="circle-"]:after {
    background-color: #FFF;
    border-radius: 50%;
    position: absolute; }
  
  .cloud .circles .circle-1 {
    width: 20px;
    height: 20px;
    top: 45px;
    left: 20px;
    z-index: 10; }
  
  .cloud .circles .circle-2 {
    width: 25px;
    height: 25px;
    top: 40px;
    left: 55px; }
  
  .cloud .circles .circle-3 {
    background-color: black;
    width: 24px;
    height: 24px;
    top: 33px;
    left: 28px;
    z-index: 0; }
    .cloud .circles .circle-3:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      top: 2px;
      left: 2px; }
  
  .cloud .circles .circle-4 {
    width: 30px;
    height: 30px;
    top: 30px;
    left: 40px; }
  
  .cloud .rect {
    background-color: #FFF;
    width: 40px;
    height: 15px;
    position: absolute;
    top: 50px;
    left: 30px; }
  
  .credit {
    position: fixed;
    bottom: 5px;
    left: 0;
    right: 0;
    font-weight: 100;
    font-size: 12px; }
    .credit a {
      color: #F9DB62;
      text-decoration: none; }

.showing {
    transform : scale(.5)
}
  
  @keyframes haze {
    0% {
      transform: translateX(-5px); }
    100% {
      transform: translateX(5px); } }
  
  @keyframes snow {
    0% {
      transform: translateY(-10px); }
    100% {
      transform: translateY(5px); } }
  
  @keyframes rain {
    0% {
      transform: translateY(-20px) rotate(20deg); }
    100% {
      transform: translateY(0) rotate(20deg); } }
  
  @keyframes flash {
    0% {
      opacity: 0; }
    4% {
      opacity: .05; }
    8% {
      opacity: 0; }
    12% {
      opacity: .05; }
    40% {
      opacity: 0; } }
  
  @keyframes thunder {
    0% {
      transform: translateY(-20px) skewX(-40deg); }
    4% {
      transform: translateY(0) skewX(-40deg); }
    8% {
      transform: translateY(-20px) skewX(-40deg); }
    12% {
      transform: translateY(0) skewX(-40deg); }
    40% {
      transform: translateY(-20px) skewX(-40deg); } }
  